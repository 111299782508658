import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import SingleQuestion from "./SingleQuestion";
import AliceCarousel from "react-alice-carousel";
import {round} from "lodash";
import {theme} from "../theme/ThemeProvider";
import {Box, Button, Rating} from "@mui/material";


function WaitingGame(props: {questions: {question: string, correctAnswer: string, answerOptions: string[], guessedOnce: boolean}[]}){
    const {t} = useTranslation();
    const [correctAnswers, setCorrectAnswers] = useState(0)
    const [wrongAnswers, setWrongAnswers] = useState(0)
    const [index, setIndex] = useState(0)

    const [answered, setAnswered] = useState(0)

    function setGuessed(i: number, value: boolean){
        props.questions[i].guessedOnce = value
    }

    function incrementCorrectAnswer() {
        setCorrectAnswers(correctAnswers+1)
        setAnswered(index+1)
    }

    function incrementWrongAnswer(){
        setWrongAnswers(wrongAnswers+1)
        setAnswered(index+1)

    }

    function createItems(){
        let items = []
        for(let i = 0; i<props.questions.length; i++){
            items.push(<SingleQuestion question={props.questions[i].question} answerOptions={props.questions[i].answerOptions} correctAnswers={props.questions[i].correctAnswer} guessedOnce={props.questions[i]?.guessedOnce ?? false} index={i} setGuessed={setGuessed} incrementCorrectAnswer={incrementCorrectAnswer} incrementWrongAnswer={incrementWrongAnswer} setIndex={setIndex} key={props.questions[i].question}/>)
        }
        return items
    }

    function determineLevel(){
        const score = correctAnswers/(correctAnswers+wrongAnswers)
        if(score <= 0.2)
            return "ergonomics_trainee"
        else if(score <= 0.5)
            return "ergonomics_padawan"
        else if(score <= 0.8)
            return "ergonomics_master"
        else if(score <= 1)
            return "ergonomics_expert"
        else
            return ""
    }

    function determineRating(){
        const score = correctAnswers/(correctAnswers+wrongAnswers)
        if(score <= 0.2)
            return 1
        else if(score <= 0.5)
            return 2
        else if(score <= 0.8)
            return 3
        else if(score <= 1)
            return 4
        else
            return 0


    }

    function slideNext(){
        setIndex(index+1)
        //setAnswered(index+1)
    }

    function slidePrevious(){
        setIndex(index-1)
    }



    return(
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", width: "100%"}}>
            <AliceCarousel items={createItems()} disableDotsControls={true} activeIndex={index} disableButtonsControls={true} touchTracking={false} />
            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                <Button onClick={slidePrevious} disabled={index === 0} style={{alignSelf: "start", color: index === 0 ? theme.palette.secondary.main : "white"}} variant={"contained"}>{t("previous")}</Button>
                <Button onClick={slideNext} disabled={index >= answered} style={{alignSelf: "end", color: index >= answered ? theme.palette.secondary.main : "white"}} variant={"contained"}>{t("next")}</Button>
            </div>
            <p style={{alignSelf: "center"}}>{t("question_progress",{count: correctAnswers+wrongAnswers, totalCount: props.questions.length})}</p>
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: "2em"}}>
                    < Rating readOnly
                    value={determineRating()}
                    max={4}
                    style={{alignSelf: "center"}}
                    icon={<img src="/bike.jpg" style={{height: "2em"}} alt={""}/>}
                    emptyIcon={<img src="bike_grey.jpg" style={{height: "2em"}} alt={""}/>}
                    />
                    {determineLevel() !== '' &&
                        <Box style={{textAlign: "center"}}>
                            {t("score", {score: (correctAnswers + wrongAnswers) ? round((correctAnswers / (correctAnswers + wrongAnswers)) * 100, 0) : 0,})}
                            <br/>
                            {t("level", {level: t(determineLevel()) })}
                        </Box>
                    }
                </div>
        </div>
    )
}

export default WaitingGame