import React, {useState} from "react";

interface InformationVideoFrameProps {
    language?: "de" | "en"
}

export function InformationVideoFrame({language}: InformationVideoFrameProps) {
    const [isMobile, _] = useState(window.innerWidth <= 768);

    let videoUrl = language === "de" ?
        "https://customer-q2hkke27o6hb7oaf.cloudflarestream.com/e57c6a2e4e95e007468822dd7d384739/iframe?poster=https%3A%2F%2Fcustomer-q2hkke27o6hb7oaf.cloudflarestream.com%2Fe57c6a2e4e95e007468822dd7d384739%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
        : "https://customer-q2hkke27o6hb7oaf.cloudflarestream.com/501eaf0b1e5959f58c62eb0f1ee81410/iframe?poster=https%3A%2F%2Fcustomer-q2hkke27o6hb7oaf.cloudflarestream.com%2F501eaf0b1e5959f58c62eb0f1ee81410%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"

    return isMobile ? (
        <div style={{position: 'relative', paddingTop: '177.77777777777777%'}}>
            <iframe
                src="https://customer-q2hkke27o6hb7oaf.cloudflarestream.com/93fa8801965235df21ad77becdb2af1a/iframe?poster=https%3A%2F%2Fcustomer-q2hkke27o6hb7oaf.cloudflarestream.com%2F93fa8801965235df21ad77becdb2af1a%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                style={{
                    border: 'none',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%'
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                allowFullScreen
            />
        </div>
    ) : (
        <div style={{position: 'relative', paddingTop: '56.25%'}}>
            <iframe
                src={videoUrl}
                style={{
                    border: 'none',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%'
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
}