import React, {FormEvent, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next'
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    TextField,
    Typography
} from "@mui/material";
import {theme} from "../theme/ThemeProvider"
import {QRCode as QRCodeFrame} from "react-qrcode-logo";
import WaitingGameFrame from "./WaitingGameFrame";
import {Language} from "../App";
import {SendErgonomicsTicketDto} from "../dto/SendErgonomicsTicketDto";
import {sendErgonomicsTicket} from "../services/EmailService";

type QRCode = {
    version: 1,
    source: 'eurobike_landing_page' | 'eurobike_email',
    language: Language,
    data: {
        name: string,
        email: string,
    }
};

function ContentFrame(props: { language: Language }) {
    const {t} = useTranslation();
    const [dataCollectionModalVisibility, setDataCollectionModalVisibility] = useState(false)
    const [pictureUsageModalVisibility, setPictureUsageModalVisibility] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mail, setMail] = useState('')
    const [dataCollectionAgreed, setDataCollectionAgreed] = useState(false)
    const [pictureUsageAgreed, setPictureUsageAgreed] = useState(false)
    const [validMail, setValidMail] = useState(false)
    const [hitSend, setHitSend] = useState(false)
    const [qrCode, setQrCode] = useState<QRCode>()
    const stringifiedCode = JSON.stringify(qrCode)
    const [disabled, setDisabled] = useState(true)
    const [subscribedNewsletter, setSubscribedNewsletter] = useState(false)
    const [blurred, setBlurred] = useState(false)

    const [showQRCode, setShowQRCode] = useState(false)
    const [ticketSendStatus, setTicketSendStatus] = useState<'sending' | 'success' | 'error'>('sending');
    useEffect(() => validateInputs(), [firstName, lastName, mail, dataCollectionAgreed, pictureUsageAgreed, validMail, subscribedNewsletter])
    useEffect(() => generateQRCode(), [firstName, lastName, mail, props.language])

    async function onSubmit(event: FormEvent) {
        setHitSend(true)
        event.preventDefault()
        validateMail()
        if (firstName === '' || lastName === '' || !dataCollectionAgreed || !pictureUsageAgreed || !validMail) {
            return
        }

        generateQRCode();
        setShowQRCode(true)
        if (subscribedNewsletter) {
            subscribeToNewsletter()
        }
        await sendTicketViaMail()
    }

    function composeFullName() {
        return firstName + ' ' + lastName;
    }

    async function sendTicketViaMail() {
        const emailQRCode = {
            ...qrCode,
            source: 'eurobike_email'
        };
        const body: SendErgonomicsTicketDto = {
            emailAddress: mail,
            name: composeFullName(),
            language: props.language.toLowerCase(),
            qrCode: JSON.stringify(emailQRCode)
        }

        try {
            const response = await sendErgonomicsTicket(body)
            const success = response.data.sendErgonomicsTicket.success;
            const status = success ? 'success' : 'error';
            setTicketSendStatus(status);
        } catch (e) {
            console.error(`Error while sending ticket: ${e}`)
            setTicketSendStatus('error')
        }
    }

    function subscribeToNewsletter() {
        // Subscribe to newsletter
        let newsletterSubmitUrl = "";
        switch (props.language) {
            case "de":
                newsletterSubmitUrl = "https://be62a51d.sibforms.com/serve/MUIEAPuzM__wyE060G7wvSVni7OdrXO7y_rxjboucU5L1UoVorwdpEgHhhPHr6y6kLwrEZs1YKA-QIwEa_qcH8N6tkzpNbYAYA0ofQCAdisRbtRvkQH77G-mac3AbbVJQYDvriEtNTz6RejfwpPXtvpMc2LIWco33d7v-wtQeqOP2cPqOU2I1oQBhj6d0TmO6qm2b3d3vS4HpBSc"
                break
            case "en":
                newsletterSubmitUrl = "https://be62a51d.sibforms.com/serve/MUIEAEeyxICHXIyIcg7izXrraLtkl_MbZyYzci6TMvLDhoX7qcln1WzMwMDVTDGJd6KdgP-0j9aK_bQwDDbX0r-c13hT4ZgXbfa9C1XGz_-4cpih3OY26s9BSKmzOt2BAPa5UVbo44YdHLZj2z8KBGCv6oSZ2FD6jXd6tCb7_t6OjusOlCQXQwk6UrVMlXKjT-6Xw6xfMgZUkvxF"
                break
            default:
                break
        }

        const formData = new FormData();

        formData.append('VORNAME', firstName);
        formData.append('NACHNAME', lastName);
        formData.append('EMAIL', mail);

        let options = {
            method: 'POST',
            body: formData
        };

        fetch(newsletterSubmitUrl, options)
            .then(res => res.json())
            .then(json => console.log(json))
            .catch(err => console.error('error:' + err));
    }

    function validateInputs() {
        validateMail()
        if (firstName !== '' && lastName !== '' && dataCollectionAgreed && pictureUsageAgreed && validMail) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    function generateQRCode() {
        setQrCode({
            version: 1,
            source: 'eurobike_landing_page',
            language: props.language,
            data: {
                name: composeFullName(),
                email: mail
            }
        })
    }

    function validateMail() {
        // Validate email address
        if (typeof mail !== "undefined") {
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (pattern.test(mail)) {
                setValidMail(true)
            } else
                setValidMail(false)
        } else {
            setValidMail(false)
        }
    }

    function hideQRCode() {
        setShowQRCode(false)
    }

    function enableMailErrorMessage() {
        setBlurred(true)
    }

    const style = {
        px: 4,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: 'min-content',
        position: 'relative',
        transform: 'translate(-50%, 0)',
        left: '50%',
        padding: "1em",
        maxWidth: Math.min(window.innerWidth, window.innerHeight)
    };


    function getTicketSendStatusTKey() {
        switch (ticketSendStatus) {
            case 'sending':
                return "sending_ticket"
            case 'success':
                return "ticket_sent"
            case 'error':
                return "ticket_not_sent"
        }
    }

    return (
        <div>
            <h1 style={{color: theme.palette.primary.main, marginBottom: "0", marginTop: "0.5em"}}>{t("sign_up")}</h1>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-center"}}>
                <form onSubmit={onSubmit}
                      style={{display: "flex", flexDirection: "column", alignItems: "flex-center"}}>
                    <label style={{
                        alignSelf: "start",
                        letterSpacing: "1.5px",
                        fontWeight: "bold",
                        fontSize: '1rem',
                        color: theme.palette.secondary.main
                    }}>{t("name").toUpperCase()}</label>
                    <TextField id="first_name" placeholder={t("enter_first_name")} color={"secondary"} required
                               sx={{input: {color: theme.palette.secondary.main}}} style={{margin: 5}}
                               onChange={(value) => {
                                   setFirstName(value.target.value.trim())
                                   validateInputs()
                               }}/>
                    <TextField id="last_name" placeholder={t("enter_last_name")} color={"secondary"} required
                               sx={{input: {color: theme.palette.secondary.main}}} style={{margin: 5}}
                               onChange={(value) => {
                                   setLastName(value.target.value.trim())
                                   validateInputs()
                               }}/>
                    <label style={{
                        alignSelf: "start",
                        letterSpacing: "1.5px",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        marginTop: "1em",
                        color: theme.palette.secondary.main
                    }}>{t("e_mail").toUpperCase()}</label>
                    <span onBlur={enableMailErrorMessage} style={{margin: 5}}>
            <TextField id="e_mail" placeholder={t("enter_e_mail")} color={"secondary"} required
                       sx={{input: {color: theme.palette.secondary.main}}}
                       style={{color: theme.palette.secondary.main, width: "100%"}} onChange={(value) => {
                setMail(value.target.value.trim())
                validateInputs()
            }}/>
          </span>
                    {!validMail && blurred
                        ? <div style={{
                            alignSelf: "start",
                            color: theme.palette.primary.main,
                            margin: 5,
                            fontSize: '1rem'
                        }}>
                            {t("valid_mail_required")}
                        </div>
                        : null
                    }

                    <FormControl
                        error={!dataCollectionAgreed}
                        sx={{m: 0.6}}
                    >
                        <FormControlLabel
                            control={<Checkbox/>}
                            label={<Typography variant={'body1'} style={{fontSize: "0.8em"}}>
                                <Trans i18nKey={"agree_data_collection_short"}>
                                    I agree to the declaration of consent for the <a href="#dataCollectionModal"
                                                                                     onClick={() => setDataCollectionModalVisibility(true)}
                                                                                     style={{
                                                                                         color: theme.palette.primary.main,
                                                                                         textDecoration: "none"
                                                                                     }}> processing of data concerning
                                    my health complaints </a>.
                                </Trans>
                            </Typography>}
                            style={{color: theme.palette.secondary.main, textAlign: "start"}}
                            onChange={(value, checked) => {
                                setDataCollectionAgreed(checked)
                                validateInputs()
                            }}/>
                        {!dataCollectionAgreed && hitSend
                            ? <FormLabel
                                style={{
                                    alignSelf: "start",
                                    color: theme.palette.primary.main
                                }}>{t("privacy_check_required")}</FormLabel>
                            : null
                        }
                    </FormControl>
                    <FormControl
                        error={!pictureUsageAgreed}
                        sx={{m: 0.6}}
                    >
                        <FormControlLabel
                            control={<Checkbox/>}
                            label={
                                <Typography variant={'body1'} style={{fontSize: "0.8em"}}>
                                    <Trans i18nKey={"agree_picture_usage_short"}>
                                        I agree to the consent form for the <a href={t("#terms_of_use_link")}
                                                                               onClick={() => setPictureUsageModalVisibility(true)}
                                                                               style={{
                                                                                   color: theme.palette.primary.main,
                                                                                   textDecoration: "none"
                                                                               }}>processing of my photographs</a>.
                                    </Trans>
                                </Typography>}
                            style={{color: theme.palette.secondary.main, textAlign: "start"}}
                            onChange={(value, checked) => {
                                setPictureUsageAgreed(checked)
                                validateInputs()
                            }}/>
                        {!pictureUsageAgreed && hitSend
                            ? <FormLabel
                                style={{
                                    alignSelf: "start",
                                    color: theme.palette.primary.main
                                }}>{t("privacy_check_required")}</FormLabel>
                            : null
                        }
                    </FormControl>
                    <FormControl sx={{m: 0.6}}>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={subscribedNewsletter}/>}
                            label={
                                <Typography variant={'body1'} style={{fontSize: "0.8em"}}>
                                    <Trans i18nKey={"subscribe_newsletter"}>
                                        I would like to subscribe to the SQlab newsletter and agree to the processing according to the <a href={t("newsletter_agreement_link")}
                                                              style={{
                                                                  color: theme.palette.primary.main,
                                                                  textDecoration: "none"
                                                              }}>privacy policy</a> (optional).
                                    </Trans>
                                </Typography>}
                            style={{color: theme.palette.secondary.main, textAlign: "start"}}
                            onChange={(value, checked) => {
                                setSubscribedNewsletter(checked)
                            }}
                        />
                    </FormControl>
                    <Typography variant={"body1"} style={{
                        fontSize: "0.8em",
                        color: theme.palette.secondary.main,
                        marginTop: "1em"
                    }}><b>{t("consent")}</b></Typography>
                    <Button
                        variant={"contained"}
                        style={{
                            color: disabled ? theme.palette.secondary.main : "white",
                            marginTop: "1em",
                            backgroundColor: disabled ? "#e0e0e0" : theme.palette.primary.main,
                            textTransform: 'none'
                        }}
                        type="submit"
                        onClick={validateMail}
                        disabled={disabled}
                    >
                        {t('send')}
                    </Button>
                </form>
                {qrCode &&
                    <Modal open={showQRCode}>
                        <Box sx={{overflowX: 'scroll', maxHeight: '100%'}}>
                            <Card sx={{...style}}>
                                <Typography variant="h2" style={{
                                    marginTop: "1em",
                                    color: theme.palette.primary.main,
                                    textAlign: "center"
                                }}> {t("ergonomics_ticket")}</Typography>
                                <p style={{
                                    textAlign: "center",
                                    color: theme.palette.secondary.main
                                }}> {t("qr_code_info_text")} </p>
                                <QRCodeFrame value={stringifiedCode} style={{margin: "1em", maxWidth: "100%"}}
                                             size={Math.min(window.innerWidth * 0.75, window.innerHeight * 0.75, 300)}/>
                                <p style={{
                                    textAlign: "center",
                                    color: theme.palette.secondary.main
                                }}> {t(getTicketSendStatusTKey())} </p>
                                <Button
                                    variant={"contained"}
                                    style={{alignSelf: "end", color: "white", marginTop: "1em"}}
                                    onClick={hideQRCode}
                                    color={"primary"}
                                >
                                    {t("close")}
                                </Button>
                                <WaitingGameFrame/>
                            </Card>
                        </Box>
                    </Modal>
                }
                <Modal open={dataCollectionModalVisibility} id="data_collection_modal">
                    <Card sx={{...style}}>
                        <Typography variant={'body1'} style={{margin: "1em", width: "100%"}}>
                            <Trans i18nKey={"agree_data_collection"}>
                                in the <a href={t("privacy_policy_link")}
                                          style={{color: theme.palette.primary.main, textDecoration: "none"}}> privacy
                                policy</a>.
                            </Trans>
                        </Typography>
                        <Button onClick={() => setDataCollectionModalVisibility(false)} variant={"contained"}
                                style={{color: "white"}}>
                            {t("understood")}
                        </Button>
                    </Card>
                </Modal>
                <Modal open={pictureUsageModalVisibility} id="#pictureUsage">
                    <Card sx={{...style}}>
                        <Typography variant={'body1'} style={{margin: "1em", width: "100%"}}>
                            <Trans i18nKey="terms_of_use">
                                The <a href={t("terms_of_use_link")}
                                       style={{color: theme.palette.primary.main, textDecoration: "none"}}>Terms and
                                Conditions</a> and
                            </Trans>
                            <Trans i18nKey="privacy_policy">
                                the <a href={t("privacy_policy_link")}
                                       style={{color: theme.palette.primary.main, textDecoration: "none"}}>Privacy
                                Policy</a> shall apply.
                            </Trans>
                        </Typography>
                        <Button onClick={() => setPictureUsageModalVisibility(false)} variant={"contained"}
                                style={{color: "white"}}>
                            {t("understood")}
                        </Button>
                    </Card>
                </Modal>
            </div>
        </div>
    );


}

export default ContentFrame;
