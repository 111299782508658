import React, {useState} from 'react';
import './App.css';
import {Card, CardContent, MenuItem, Select, Typography} from "@mui/material";
import ContentFrame from "./components/ContentFrame";
import {theme} from "./theme/ThemeProvider";
import {useTranslation} from "react-i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {InformationVideoFrame} from "./components/InformationVideoFrame";

export type Language = "de" | "en";

function App() {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState<Language>(navigator.language.split("-")[0] === "de" ? 'de' : 'en')

    let img = []
    if (language === 'de') {
        img = [
            <img src="/sitbone_de.jpg" className={"carouselImg"} alt="sitbone_de"/>,
            <img src="/hand_de.jpg" className={"carouselImg"} alt="hand_de"/>,
            <img src="/body_after_de.jpg" className={"carouselImg"} alt="body_de"/>,
        ]
    } else {
        img = [
            <img src="/sitbone_en.jpg" className={"carouselImg"} alt="sitbone_en"/>,
            <img src="/hand_en.jpg" className={"carouselImg"} alt="hand_en"/>,
            <img src="/body_after_en.jpg" className={"carouselImg"} alt="body_en"/>,
        ]
    }

    function handleChange(lang: any) {
        i18n.changeLanguage(lang === "de" ? "de" : "en-en")
        setLanguage(lang === "de" ? 'de' : 'en')
    }

    return (
        <div className="App">
            <aside style={{position: "fixed", right: 0, bottom: 0, margin: "1em", fontSize: "0.5em", zIndex: "999"}}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    renderValue={selected => selected === "de" ? "🇩🇪" : "🇬🇧"}
                    label="Language"
                    onChange={item => handleChange(item.target.value)}
                    size={"small"}
                    style={{background: theme.palette.secondary.main, height: "max-content"}}
                >
                    <MenuItem key={"de"} value={"de"}>🇩🇪 {t("de")}</MenuItem>
                    <MenuItem key={"en"} value={"en"}>🇬🇧 {t("en")}</MenuItem>
                </Select>
            </aside>
            <header className="App-header">
                <div className="logo-box">
                    <img src="/sqlab-logo.png" className="logo" alt="logo"/>
                </div>
            </header>

            <div style={{margin: "1em", alignItems: "center"}}>
                <Typography variant={"h1"} style={{
                    margin: "0.5em",
                    marginBottom: "0em",
                    color: theme.palette.primary.main
                }}>{t("page_title")}</Typography>
                <Typography variant={"h4"} style={{
                    marginBottom: "1em",
                    fontWeight: 1000,
                    color: theme.palette.primary.main
                }}>{t("page_subtitle")}</Typography>
                <Typography variant={"h6"} style={{
                    marginBottom: "1em",
                    color: theme.palette.secondary.main
                }}>{t("apply_now")}</Typography>
                <Typography variant={"h6"} style={{
                    marginBottom: "1em",
                    color: theme.palette.secondary.main
                }}>{t("further_info")}</Typography>

                <div style={{display: "flex", margin: "1em", justifyContent: "center"}}>
                    <Card style={{
                        display: "flex",
                        marginBottom: "5em",
                        marginTop: "2em",
                        maxWidth: "600px",
                    }} sx={{boxShadow: 10}}>
                        <CardContent style={{maxWidth: "500px", overflowY: "scroll"}}>
                            <ContentFrame language={language}/>
                        </CardContent>
                    </Card>
                </div>

                <InformationVideoFrame language={language} />

                <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: '2em'}}>
                    <div style={{maxWidth: "600px", width: "100%"}}>
                        <AliceCarousel items={img} autoPlay={true} infinite={true} autoPlayInterval={5000}
                                       controlsStrategy={"alternate"} disableButtonsControls={true}/>
                    </div>
                </div>
            </div>
            <footer>
                <p style={{marginBottom: "3em"}}>© SQlab GmbH 2024 - <a href={t("imprint_link")} style={{
                    color: theme.palette.primary.main,
                    textDecoration: "none"
                }}>{t("imprint")}</a></p>
            </footer>
        </div>

    );
}

export default App;
