import React, {ChangeEvent, useState} from "react";
import {Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {theme} from "../theme/ThemeProvider";
import {useTranslation} from "react-i18next";

function SingleQuestion(props: {question: string, answerOptions: string[], correctAnswers: string, guessedOnce: boolean, index: number, setGuessed: Function, incrementWrongAnswer: Function, incrementCorrectAnswer: Function, setIndex: Function}){
    const {t} = useTranslation();
    const [correctAnswer, setCorrectAnswer] = useState(false)
    const [wrongAnswer, setWrongAnswer] = useState(false)

    const [selected, setSelected] = useState('')
    const [guessedAlready, setGuessedAlready] = useState(props.guessedOnce)

    async function handleChange(event: ChangeEvent<HTMLInputElement>){
        setSelected(event.target.name);
    };


    function checkAnswer(){
        props.setGuessed(props.index, true)
        setGuessedAlready(true)
        props.setIndex(props.index)
        if(selected === props.correctAnswers) {
            setCorrectAnswer(true)
            setWrongAnswer(false)
            props.incrementCorrectAnswer()
            return true
        } else {
            setWrongAnswer(true)
            setCorrectAnswer(false)
            props.incrementWrongAnswer()
            return false
        }
    }

    return(
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", width: "100%"}}>
            <Typography variant={'h3'} color={theme.palette.secondary.main} style={{ marginTop: "2em"}}>{t("waiting_heading")}</Typography>
            <Typography variant={'body1'} color={theme.palette.secondary.main} style={{marginTop: "2em", marginBottom: "2em"}}>{t("waiting_explanation")}</Typography>

            <Typography variant={'body1'} color={theme.palette.primary.main} style={{marginBottom: "0.5em", textAlign: "start"}}>{props.question}</Typography>
                <FormControl>
                    <RadioGroup>
                        {props.answerOptions.map((option: string, index) => {
                                return <FormControlLabel
                                    control={<Radio onChange={handleChange} name={option} disabled={guessedAlready}/>}
                                    label={<Typography variant={'body1'} style={{fontSize: "0.8em"}}>{option}</Typography>}
                                    style={{color: theme.palette.secondary.main}}
                                    value={index}
                                    key={option}
                                />
                            }
                        )}
                    </RadioGroup>
                </FormControl>
            {correctAnswer &&
                <FormControl
                    error={correctAnswer}
                    sx={{m: 0.6}}
                    style={{color: "green"}}
                > {t("correct_answer")}
            </FormControl>}
            {wrongAnswer &&
                <FormControl
                    error={correctAnswer}
                    sx={{m: 0.6}}
                    style={{color: "red"}}
                > {t("wrong_answer", {correctAnswer: props.correctAnswers})}
                </FormControl>
            }
            <Button onClick={checkAnswer} disabled={guessedAlready || selected === ''} variant={"contained"} style={{width: "max-content", alignSelf: "center", color: (guessedAlready || selected === '') ? theme.palette.secondary.main : "white", textTransform: 'none'}}>{t("check_answer")}</Button>
        </div>
    )
}

export default SingleQuestion