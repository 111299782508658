import {ReactNode} from "react";
import typography from "./typography";
import breakpoints from "./breakpoints";
import {
    createTheme,
    ThemeOptions,
    ThemeProvider as MUIThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

type Props = {
    children: ReactNode;
};

const themeOptions: ThemeOptions = {
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        direction: 'rtl',
    }


export const theme = createTheme({
    palette: {
        primary: {
            main: '#EC7404'
        },
        secondary: {
            main: '#9C9E9F'
        }
    },
    ...themeOptions
});

export default function ThemeProvider({children}: Props){

    return(
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    )
}