import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "enter_first_name": "Enter your first name",
            "enter_last_name": "Enter your last name",
            "enter_e_mail": "Enter your email address",
            "name": "Name",
            "e_mail": "E-Mail",
            "privacy_policy_link": "https://profiler.bike/profiler-privacy-policy",
            "privacy_policy": "the <1>Privacy Policy</1> shall apply.",
            "agree_data_collection_short": "I agree to the declaration of consent for the <1> processing of data concerning my health complaints</1>.",
            "agree_data_collection": "I consent that SQlab GmbH, Postweg 4, 82024 Taufkirchen, Germany, records data on my health complaints as part of the app in order to suggest suitable bicycle products to me. I can revoke my consent with effect in the future, e.g. by sending an e-mail to info@sq-lab.com. Further information in the <1>privacy policy</1>. ",
            "terms_of_use_link": "https://profiler.bike/profiler-terms-of-use",
            "terms_of_use": "The app is provided free of charge by SQlab GmbH, Postweg 4, 82024 Taufkirchen. In return for using the app free of charge, users shall provide their photographs. SQlab GmbH pixellates faces in the photo recordings and may use this data to improve its offer (in particular for its algorithms).\n" +
                "\n" +
                "I declare that I am over 16 years old. The <1>Terms and Conditions</1> and ",
            "agree_picture_usage_short": "I agree to the consent form for the <1>processing of my photographs</1>.",
            "understood": "Understood",
            "newsletter_agreement_link": "https://profiler.bike/datenschutzerklarung",
            "subscribe_newsletter": "I would like to subscribe to the SQlab newsletter and agree to the processing" +
                " according to the <1>privacy policy</1> (optional).",
            "consent": "By starting my survey, I confirm that I have read and understood the text of both consent" +
                " forms.",
            "send": "Get Your Free Ergonomics Ticket",
            "sign_up": "Registration",
            "sign_up_info": "Please enter your contact information here - this will allow us to send you our product" +
                " recommendations after your  measurement.",
            "valid_mail_required": "Please enter a valid mail address",
            "privacy_check_required": "Please give your consent",
            "page_title": "Digital measurement with SQlab",
            "page_subtitle": "Fitting products in just 5 minutes!",
            "apply_now": "Register now and save time at the event. Get measured at the Eurobike with the SQlab Profiler App, to get a personalized Ergonomics-Profile.",
            "further_info": "More information below:",
            "ergonomics_ticket": "Your personal SQlab QR-Code for the EUROBIKE 2024",
            "qr_code_info_text": "Please show this QR code to one of our employees to start the measurement" +
                " directly.",
            "close": "Close",
            "de": "Deutsch",
            "en": "English",
            "waiting_heading": "Waiting for your personal body measurement?",
            "waiting_explanation": "While waiting for your personal body measurement, we invite you to discover more" +
                " about SQlab in our interactive quiz:",
            "imprint": "Imprint",
            "imprint_link": "https://www.sq-lab.com/en/impressum/",
            "question_progress": "You have answered {{count}} out of {{totalCount}} questions!",
            "score": "So far, you have correctly answered {{score}}%.",
            "level": "According to this result you can call yourself {{level}}!",
            "ergonomics_trainee": "Ergonomics-Apprentice",
            "ergonomics_padawan": "Ergonomics-Padawan",
            "ergonomics_master": "Ergonomics-Master",
            "ergonomics_expert": "Ergonomics-Expert",
            "wrong_answer": "This answer is wrong! \n The correct answer is: {{correctAnswer}}",
            "correct_answer": "This answer is correct!",
            "next": "Next",
            "previous": "Previous",
            "check_answer": "Check My Answer!",
            "ticket_sent": "Your ticket has been sent to your email address.",
            "sending_ticket": "Sending your ticket...",
            "ticket_not_sent": "Sorry, your ticket could not be sent. Please try again later.",
        }
    },
    de: {
        translation: {
            "enter_first_name": "Vorname eingeben",
            "enter_last_name": "Nachname eingeben",
            "enter_e_mail": "E-Mail-Adresse eingeben",
            "name": "Name",
            "e_mail": "E-Mail",
            "privacy_policy_link": "https://profiler.bike/profiler-datenschutz",
            "agree_data_collection_short": "Ich stimme der Einwilligungserklärung zur <1>Verarbeitung der Daten zu meinen gesundheitlichen Angaben</1> zu.",
            "agree_data_collection": "Ich willige ein, dass die SQlab GmbH, Postweg 4, 82024 Taufkirchen im Rahmen der App Daten zu meinen gesundheitlichen Angaben aufnimmt, um mir auf dieser Grundlage passende Fahrradprodukte vorzuschlagen. Die Einwilligung ist mit Wirkung für die Zukunft widerruflich, z.B. per E-Mail an info@sq-lab.com. Weitere Informationen in der <1>Datenschutzerklärung</1>.",
            "terms_of_use_link": "https://profiler.bike/profiler-nutzungsbedingungen",
            "agree_picture_usage_short": "Ich stimme der Einwilligungserklärung zur <1>Verarbeitung meiner Fotoaufnahmen</1> zu.",
            "terms_of_use": "Die App wird Dir von der SQlab GmbH, Postweg 4, 82024 Taufkirchen kostenlos zur" +
                " Verfügung gestellt. Als Gegenleistung zur kostenlosen Nutzung der App stellen Nutzer ihre Fotoaufnahmen zur Verfügung. SQlab GmbH verpixelt Gesichter in den Fotoaufnahmen und darf diese Daten verwenden, um damit ihr Angebot (insbesondere von ihr eingesetzte Algorithmen) zu verbessern.\n" +
                "\n" +
                "Ich erkläre, dass ich über 16 Jahre alt bin. Die <1>Allgemeinen Geschäftsbedingungen</1> und ",
            "privacy_policy": "die <1>Datenschutzerklärung</1> finden Anwendung.",
            "consent": "Mit dem Start meiner Befragung bestätige ich, dass ich den Text beider" +
                " Einwilligungserklärungen gelesen und verstanden habe.",
            "understood": "Verstanden",
            "newsletter_agreement_link": "https://profiler.bike/datenschutzerklarung",
            "subscribe_newsletter": "Ich möchte den SQlab Newsletter abonnieren und bin mit der Verarbeitung gemäß der <1>Datenschutzerklärung</1>" +
                " einverstanden (optional).",
            "send": "Zum kostenlosen Ergonomie-Ticket",
            "sign_up": "Anmeldung",
            "sign_up_info": "Bitte gib hier Deine Kontaktinformationen an - damit können wir Dir nach der" +
                " Vermessung Deine Produktempfehlungen zuschicken.",
            "valid_mail_required": "Bitte gib eine korrekte E-Mail Adresse an",
            "privacy_check_required": "Bitte gib Deine Zustimmung",
            "page_title": "Digitale Vermessung mit SQlab",
            "page_subtitle": "Zu den passenden Produkten in nur 5 Minuten!",
            "apply_now": "Jetzt anmelden und vor Ort Zeit sparen. Auf der Eurobike mit der SQlab Profiler App" +
                " vermessen lassen, um ein persönliches Ergonomie-Profil zu bekommen.",
            "further_info": "Weitere Infos weiter unten:",
            "ergonomics_ticket": "Dein persönlicher SQlab QR-Code für die EUROBIKE 2024",
            "qr_code_info_text": "Bitte zeige diesen QR Code einem unserer Mitarbeiter, um direkt mit der" +
                " Vermessung zu beginnen.",
            "close": "Schließen",
            "de": "Deutsch",
            "en": "English",
            "waiting_heading": "Du wartest auf Ihre persönliche Vermessung?",
            "waiting_explanation": "Gerne kannst Du Deine Wartezeit dazu nutzen, mit unserem interaktiven Quiz" +
                " mehr über SQlab herauszufinden:",
            "imprint": "Impressum",
            "imprint_link": "https://www.sq-lab.com/impressum/",
            "question_progress": "Du hast {{count}} von insgesamt {{totalCount}} Fragen beantwortet!",
            "score": "Du hastbisher {{score}}% richtig beantwortet.",
            "level": "Mit diesem Ergebnis darfst Du Dich {{level}} nennen!",
            "ergonomics_trainee": "Ergonomie-Lehrling",
            "ergonomics_padawan": "Ergonomie-Padawan",
            "ergonomics_master": "Ergonomie-Meister",
            "ergonomics_expert": "Ergonomie-Experte",
            "wrong_answer": "Diese Antwort ist falsch! \n Die richtige Antwort lautet: {{correctAnswer}}",
            "correct_answer": "Diese Antwort ist richtig!",
            "next": "Nächste",
            "previous": "Vorherige",
            "check_answer": "Antwort überprüfen",
            "ticket_sent": "Dein Ticket wurde erfolgreich an Deine E-Mail Adresse geschickt.",
            "sending_ticket": "Dein Ticket wird an Deine E-Mail Adresse geschickt...",
            "ticket_not_sent": "Dein Ticket konnte nicht an Deine E-Mail Adresse geschickt werden.",
        }
    }
};

const lang: string = navigator.language.split("-")[0] === "de" ? 'de' : 'en'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;