export async function fetchGraphQL(
    operationsDoc: string,
    operationName: string,
    variables: Record<string, any>
) {
    return fetch('https://api.profiler.bike/v1/graphql', {
        method: 'POST',
        body: JSON.stringify({
            query: operationsDoc,
            variables,
            operationName,
        }),
    });
}