import {fetchGraphQL} from "./HasuraClient";
import {SendErgonomicsTicketDto} from "../dto/SendErgonomicsTicketDto";

const operation = `
    mutation SendErgonomicsTicket($emailAddress: String!, $name: String!, $qrCode: String!, $language: String!) {
      sendErgonomicsTicket(emailAddress: $emailAddress, name: $name, qrCode: $qrCode, language: $language) {
        success
      }
    }
`;

export async function sendErgonomicsTicket(emailDto: SendErgonomicsTicketDto) {
    const response = await fetchGraphQL(operation, "SendErgonomicsTicket", emailDto);
    return await response.json();
}